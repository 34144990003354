import { useRef, useState } from 'react';
import {
  array,
  arrayOf,
  bool,
  element,
  func,
  oneOf,
  shape,
  string,
} from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import Breadcrumb from './Breadcrumb';
import Divider from './Divider';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginRight: theme.spacing(1),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Header(props) {
  const {
    actions,
    breadcrumb,
    breadcrumbPieces,
    tabs,
    title,
    subtitle,
  } = props;
  const classes = useStyles();
  const actionRef = useRef(null);
  const [openMenu, setOpenMenu] = useState({});
  const [menuValue, setMenuValue] = useState({});

  const handleClose = function (index) {
    setOpenMenu({
      ...openMenu,
      [`actions_menu_${index}`]: false,
    });
  };

  return (<>

    <Grid
      className={classes.root}
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        {breadcrumb && <Breadcrumb
          pieces={breadcrumbPieces}
        />}
        <Typography
          children={title}
          color="textPrimary"
          variant="h3"
        />
        <Typography
          children={subtitle}
          color="textPrimary"
          variant="h6"
        />
      </Grid>

      {!!actions.length && <Grid item>
        {actions.map((item, index) => {
          let component;
          const {
            processing = false,
            disabled = processing || false,
          } = item;

          if (item.type === 'menu') {
            if (!menuValue[`actions_menu_${index}`]) {
              const selected = item.options.find(({ selected }) => selected);

              setMenuValue({
                ...menuValue,
                [`actions_menu_${index}`]: selected,
              });
            }

            component = (<>
              <Button
                children={menuValue[`actions_menu_${index}`]
                  ? menuValue[`actions_menu_${index}`].label
                  : ''}
                color={item.color}
                endIcon={openMenu[`actions_menu_${index}`]
                  ? <ExpandLessIcon />
                  : <ExpandMoreIcon />}
                ref={actionRef}
                onClick={() => setOpenMenu({
                  ...openMenu,
                  [`actions_menu_${index}`]: true,
                })}
                startIcon={item.icon}
                variant="outlined"
              />
              <Menu
                anchorEl={actionRef.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                getContentAnchorEl={null}
                onClose={() => handleClose(index)}
                open={openMenu[`actions_menu_${index}`]}
                PaperProps={{
                  className: classes.menu,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {item.options.map((option, i) => (
                  <MenuItem
                    key={i}
                    selected={option.selected}
                    onClick={function () {
                      setMenuValue({
                        ...menuValue,
                        [`actions_menu_${index}`]: option,
                      });
                      handleClose(index);

                      if (typeof option.fn === 'function') {
                        option.fn(option);
                      }
                    }}
                  >
                    {option.icon && <ListItemIcon children={option.icon} />}
                    <Typography
                      children={option.label}
                      variant="inherit"
                    />
                  </MenuItem>
                ))}
              </Menu>
            </>);
          } else {
            component = (
              <Button
                children={item.label}
                color={item.color || 'secondary'}
                disabled={disabled || processing}
                key={index}
                form={item.form}
                onClick={item.fn}
                startIcon={processing ?
                  (<CircularProgress
                    color="secondary"
                    size={18}
                    style={{
                      marginRight: 5,
                    }}
                  />)
                  : item.icon}
                style={{
                  ...{
                    marginLeft: 5,
                    marginRight: 5,
                  },
                  display: (item.hidden ? 'none' : undefined),
                  ...(item.color === 'error' && !disabled ? {
                    color: 'white',
                    backgroundColor: red[500],
                    '&:hover': {
                      backgroundColor: red[900],
                    },
                  } : {}),
                }}
                type={item.type}
                variant={item.variant || 'contained'}
              />
            );
          }

          return component;
        })}
      </Grid>}
    </Grid>

    {(tabs?.items || []).length > 0 && (<Box mt={2}>
      <Tabs
        onChange={(_, value) => tabs.onChange(value)}
        scrollButtons="auto"
        textColor="secondary"
        value={tabs.value}
        variant="scrollable"
      >
        {(tabs?.items || []).map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>)}
  </>);
}

Header.defaultProps = {
  actions: [],
  breadcrumb: true,
  subtitle: '',
};

Header.propTypes = {
  actions: arrayOf(shape({
    color: oneOf([
      'error',
      'default',
      'primary',
      'secondary',
    ]),
    disabled: bool,
    fn: func,
    form: string,
    icon: element,
    label: string,
    type: oneOf([
      'button',
      'menu',
      'submit',
    ]),
    variant: oneOf([
      'contained',
      'outlined',
      'text',
    ]),
  })),
  breadcrumb: bool,
  breadcrumbPieces: array,
  tabs: shape({
    items: arrayOf(shape({
      label: string,
      name: string,
    })),
    onChange: func,
    value: string,
  }),
  title: string,
  subtitle: string,
};

export default Header;
