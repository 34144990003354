import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {},
  firstButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  secondButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
