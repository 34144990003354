import { lazy } from 'react';

import getRoutes from './routes';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const path = '/public';

const publicRoutes = {
  path,
  routes: getRoutes(path, error404),
};

export default publicRoutes;
