import { Redirect } from 'react-router-dom';
import { node } from 'prop-types';

import { getCurrentSessionData } from 'utils/aws/cognito';

function AuthGuard({ children }) {
  const { decodeAccessToken } = getCurrentSessionData();

  if (decodeAccessToken === null || decodeAccessToken?.exp * 1000 < Date.now()) {
    const {
      hash = '',
      pathname,
      search = '',
    } = window.location;
    const redirect = Buffer
      .from(`${pathname}${search}${hash}`)
      .toString('base64');

    return (<Redirect
      to={`/auth/login?redirect=${redirect}`}
    />);
  }

  return children;
};

AuthGuard.propTypes = {
  children: node,
};

export default AuthGuard;
