import { lazy } from 'react';

export default function getRoutes(parent = '', error404) {

  return [
    {
      exact: true,
      path: `${parent}`,
      component: lazy(() => import('views/Dashboard/Home')),
    },
    error404,
  ];
}
