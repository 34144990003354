import {
  Box,
  Button,
  ButtonGroup as MuiButtonGroup,
} from '@material-ui/core';

import { DEFAULT_PROPS, PROP_TYPES } from './constants';
import useStyles from './styles';

function ButtonGroup(props) {
  const classes = useStyles();

  return (
    <Box textAlign="center" pb={props.pb} pt={props.pt}>
      <MuiButtonGroup className={classes.buttonGroup} size={props?.size}>
        {(props?.actions ?? []).map((action, index) => (
          <Button
            children={action.label}
            color={action.value !== undefined && props?.value === action.value
              ? props.color
              : 'default'}
            disableElevation
            endIcon={action.endIcon}
            key={index}
            onClick={() => {
              props.onChange(action.value);
            }}
            startIcon={action.startIcon}
            style={{
              borderRadius: 30,
              width: props?.width,              
              fontSize: props?.fontSize,
              fontFamily: 'Outfit-Regular',
              margin: 4,
              backgroundColor: action.value !== undefined && props?.value === action.value ? '#29ABBA' : '#F5F5F5',
              opacity: action.value !== undefined && props?.value === action.value ? 1 : 0.5,
              color: action.value !== undefined && props?.value === action.value ? '#ffffff' : '#000',
              fontWeight: 700,
            }}
            variant={props?.variant}
          />
        ))}
      </MuiButtonGroup>
    </Box>
  );
}

ButtonGroup.defaultProps = DEFAULT_PROPS;
ButtonGroup.propTypes = PROP_TYPES;

export default ButtonGroup;
