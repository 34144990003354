import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { node } from 'prop-types';

import { STEP } from 'views/Request/Common/constants';

import useStyles from './styles';
import TopBar from './TopBar';

const parentRoute = '/request';

function RequestLayout(props) {
  const history = useHistory();
  const classes = useStyles();

  const { location: { pathname, search } } = history;
  const onIframe = window.self !== window.top;

  const handleBack = function () {
    const backRoute = {
      [STEP.CONFIRM]: `/${STEP.IDENTITY}`,
      [STEP.IDENTITY]: `/${STEP.CUSTOMER}`,
      [STEP.LOAN]: `/${STEP.CONFIRM}`,
      [STEP.REGISTER]: `/${STEP.SIMULATOR_RESULTS}${search}`,
      [STEP.SIMULATOR_RESULTS]: search,
    }[pathname.replace(/^\/request\//, '')];

    return backRoute
      ? function () {
        history.push(`${parentRoute}${backRoute}`);
      }
      : undefined;
  };

  return (
    <div className={clsx(classes.root, onIframe ? classes.root_iframe : classes.root_self)}>

      <TopBar
        onIframe={onIframe}
        handleBack={handleBack()}
      />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div
            children={props.children}
            className={classes.content}
          />
        </div>
      </div>

    </div>
  );
}

RequestLayout.propTypes = {
  children: node,
};

export default RequestLayout;
