import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Link as RouterLink,
  useLocation,
  matchPath,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
} from '@material-ui/core';
import { Dashboard as DashboardIcon } from '@material-ui/icons';

import Logo from 'components/Logo';

import NavItem from './NavItem';
import useStyles from './styles';



function renderNavItems({ items = [], ...rest }, t = (s) => s) {

  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          ...rest,
        }, t),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }, t = (s) => s) {
  const key = item.title + depth;
  let title = t(`system.menu.navigation.items.${item.title}`);

  if (title === `system.menu.navigation.items.${item.title}`) {
    title = item.title ?? '';
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items.filter((_) => !_.hidden),
        }, t)}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={title}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, openMenu, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const navConfig = [
    {
      items: [
        {
          href: '/',
          icon: DashboardIcon,
          module: 'dashboard',
          title: 'dashboard',
        },
      ],
      permissions: [],
      subheader: '',
    },
  ];

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{
        suppressScrollX: true,
      }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo
                style={{
                  maxHeight: 48,
                }}
                variant="imagotipo-horizontal"
              />
            </RouterLink>
          </Box>
        </Hidden>

        <Divider />

        <Box p={2}>
          {navConfig.map((config, index) => (<>
            {((config?.items ?? []).length) > 0 && (<List
              children={renderNavItems({
                items: config.items,
                pathname: location.pathname,
              }, t)}
              key={index}
              subheader={
                <ListSubheader
                  children={config.subheader
                    ? t(`system.menu.navigation.groups.${config.subheader}`)
                    : ''}
                  disableGutters
                  disableSticky
                />
              }
            />)}
          </>))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        />
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes[openMenu
              ? 'desktopDrawer'
              : 'desktopMiniDrawer'],
          }}
          open={openMenu}
          variant="persistent"
        />
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openMenu: PropTypes.bool,
};

export default NavBar;
