import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from '@material-ui/core';

import { Loader } from 'components';

function LoadingScreen() {

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (<Box
    children={<Loader />}
    mb={10}
    mt={10}
    textAlign="center"
  />);
}

export default LoadingScreen;
