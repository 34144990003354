import axios from 'axios';
import { stringify } from 'querystring';

import { API_CONNECTOR_URL as baseURL } from 'settings';

import { processResponse } from './functions/response';

const http = axios.create({
  baseURL,
});

export const SERVICES = {
  APPS_SAT_TOKEN: '/apps/token/ciec',

  KYC_CHECK: '/kyc/:type/check',

  PAYMENTS: '/payments/:flowId',

  PUBLIC_ZIPCODE: '/public/zipcode',
};

let resource;

/**
 * Create a resource
 *
 * @param {object} payload
 * @returns
 */
async function create(payload) {
  const response = await http.post(resource, payload);
  const { data: { data = [] } } = response;
  let values;

  if (Array.isArray(data)) {
    [values] = data;
  } else {
    values = data;
  }

  return values;
}

/**
 * Read resources
 *
 * @param {object} filters
 * @param {boolean} sort
 * @returns
 */
async function read(filters = {}, sort = false) {
  const qs = stringify(filters);
  const response = await http.get(`${resource}?${qs}`);

  return processResponse(response, filters, sort);
}

/**
 *
 * @param {string} service
 * @param {object} params
 * @returns
 */
export const Connector = (service, params = {}) => {
  Object
    .entries(params)
    .forEach(function ([param, value]) {
      service = service.replace(`:${param}`, value);
    });

  resource = service;

  return {
    SERVICES,
    create,
    read,
    get: read,
    post: create,
  };
};
