import { AES, enc as encoder } from 'crypto-js';

import { pickValue } from 'utils/objects';

const STORAGE_KEY = 'settings';
const SECRET = '0hiBR1dOkupR1tIc';
const { REACT_APP_CONFIG_FILE } = process?.env;

export function getSettings(key, defaultValue = null) {
  try {
    const settings = JSON.parse(AES
      .decrypt(localStorage.getItem(STORAGE_KEY), SECRET)
      .toString(encoder.Utf8));

    if (key) {
      return pickValue(settings, key) ?? defaultValue;
    }
    else {
      return settings;
    }
  } catch (error) {
    return defaultValue;
  }
};

export async function initSettings() {
  const { hostname } = window.location;
  const file = [
    process.env.PUBLIC_URL,
    '/static/json/',
    REACT_APP_CONFIG_FILE ? REACT_APP_CONFIG_FILE : hostname.replace(/\./gi, '_'),
    '.json?rnd=',
    Math.random() * 10000,
  ].join('');

  try {
    const data = await (await fetch(file)).json();

    console.debug(`Init config for: ${hostname}`);

    const settings = AES
      .encrypt(JSON.stringify(data), SECRET)
      .toString();

    localStorage.setItem(STORAGE_KEY, settings);

    return data;
  } catch (error) {
    console.debug(`Init config error for: ${hostname}`);
    console.error(error);

    return {};
  }
}
