import { pickValue } from './objects';

/**
 * Change first letter for a string
 *
 * @param {string} str String to change
 * @param {boolean} capitalize If capitalize change first letter to uppercase
 * @returns {string} Modified string
 */
function firstLetter(str, capitalize = true) {
  let output = str
    .charAt(0)[capitalize
      ? 'toUpperCase'
      : 'toLowerCase']();
  output += str
    .slice(1);

  return output;
}

/**
 * Parse string values to JavaScript data types
 *
 * @param {string} str
 * @returns {any}
 */
function parseValue(str) {
  let val = str.trim();

  if (val === 'true') {
    val = true;
  } else if (val === 'false') {
    val = false;
  }

  if (String(parseFloat(val)) === val) {
    val = parseFloat(val) || val;
  }

  return val;
}

/**
 * Pupulate string with values in object
 *
 * @param {string} str String to populate
 * @param {object} values Data to populate string
 * @param {string} prefix Prefix for replacement values
 * @returns {string} Populate string
 */
function populate(str, values = {}, prefix = ':') {
  let output = str;

  for (const [k, v] of Object.entries(values)) {
    const key = `${prefix}${k}`;

    output = (v !== null && typeof v === 'object')
      ? populate(output, v, `${key}.`)
      : replaceAll(output, key, v);
  }

  return output;
}

/**
 *
 * @param {*} str
 * @param {*} search
 * @param {*} replacement
 * @returns
 */
function replaceAll(str, search, replacement) {
  return str
    .split(search)
    .join(replacement);
}

/**
 * Slugifies a string
 *
 * @param {string} str String to slugify
 * @param {object} options Obtions for string format
 * @returns {string}
 */
function slugify(str, options) {
  const {
    replacement = '_',
    upperCase = false,
  } = options || {};

  let output = str
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase();

  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    output = output.replace(
      new RegExp(from.charAt(i), 'g'),
      to.charAt(i),
    );
  }

  output = output
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, replacement)
    .replace(/-+/g, replacement);

  if (upperCase) {
    output = output.toUpperCase();
  }

  return output;
}

/**
 * Generate string from template
 *
 * @param {string} str Input template string
 * @param {object} values Data to populate template
 * @param {*} options
 * @returns {string}
 */
function renderString(str, values, options) {
  const pattern = /{([^{}]+)}/g;
  const replacer = (_, key) => pickValue(
    values,
    key.trim(),
    options?.separator ?? '.',
  ) ?? '';

  return str.replace(pattern, replacer);
}

export {
  firstLetter,
  parseValue,
  populate,
  replaceAll,
  renderString,
  slugify,
};
