import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import { createElement } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';

import { configureStore } from 'redux/store';

import App from './App';
import './global.css';
import './locales';
enableES5();

const store = configureStore();

if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

render(
  createElement(Provider, {
    children: createElement(App),
    store,
  }),
  document.getElementById('root'),
);
