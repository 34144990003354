import { Fragment } from 'react';
import clsx from 'clsx';
import {
  func,
  node,
  object,
  oneOf,
  string,
} from 'prop-types';
import { Tooltip } from '@material-ui/core';

import useStyles from './styles';

function Label(props) {
  const classes = useStyles();
  const Container = props.title
    ? Tooltip
    : Fragment;

  const containerProps = props.title
    ? {
      arrow: true,
      placement: 'top',
      title: props.title,
    }
    : {};

  return (
    <Container {...containerProps}>
      <span
        children={props.children}
        className={clsx(
          classes.root,
          (props.variant === 'uppercase' ? classes.uppercase : undefined),
          (props.onClick ? classes.isButton : undefined),
          {
            [classes[props.color]]: props.color,
          },
          props.className,
        )}
        onClick={props.onClick ? props.onClick : undefined}
      />
    </Container>
  );
}

Label.defaultProps = {
  className: '',
  color: 'secondary',
  variant: 'normal',
};

Label.propTypes = {
  children: node,
  className: string,
  color: oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  onClick: func,
  style: object,
  title: string,
  variant: oneOf([
    'normal',
    'uppercase',
  ]),
};

export default Label;
