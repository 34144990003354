import {
  arrayOf,
  element,
  func,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';

export const DEFAULT_PROPS = {
  actions: [],
  color: 'primary',
  onChange: () => { },
  size: 'medium',
  variant: 'contained',
};

export const PROP_TYPES = {
  actions: arrayOf(shape({
    endIcon: element,
    fn: func,
    label: string,
    startIcon: element,
    value: string,
  })),
  color: oneOf([
    'primary',
    'secondary',
  ]),
  fontSize: string,
  onChange: func,
  pb: number,
  pt: number,
  size: oneOf([
    'large',
    'medium',
    'small',
  ]),
  value: string,
  variant: oneOf([
    'contained',
    'outlined',
    'text',
  ]),
  width: number,
};
