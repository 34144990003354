import { lazy } from 'react';

import { AuthenticationLayout } from 'layouts';

import getRoutes from './routes';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const path = '/auth';

const dashboardRoutes = {
  layout: AuthenticationLayout,
  path,
  routes: getRoutes(path, error404),
};

export default dashboardRoutes;
