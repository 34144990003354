import {
  forwardRef,
  useEffect,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  node,
  oneOf,
  string,
} from 'prop-types';
import {
  Container,
  makeStyles,
} from '@material-ui/core';

import track from 'utils/analytics';
import { getSettings } from 'utils/settings';

const onIframe = window.self !== window.top;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EEF6F8',
    maxHeight: onIframe ? '100%' : undefined,
    minHeight: '100%',
    overflow: onIframe ? 'hidden' : undefined,
  },
  container: {
    padding: '0px',
    paddingRight: '0px',
  },
}));

const Page = forwardRef(({ children, maxWidth, title, ...rest }, ref) => {
  const classes = useStyles();
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  useEffect(() => {
    const site = getSettings('site');

    document.title = `${title ? `${title} | ` : ''}${site?.title}`;

    if (site?.favicon) {
      document.querySelector('link[rel="icon"]').href = site.favicon;
    }
  }, [title]);

  return (
    <div className={classes.root} ref={ref} {...rest}>
      <Container
        children={children}
        className={classes.container}
        maxWidth={maxWidth}
      />
    </div>
  );
});

Page.defaultProps = {
  maxWidth: false,
};

Page.displayName = 'Page';

Page.propTypes = {
  children: node,
  maxWidth: oneOf([
    'lg',
    'md',
    'sm',
    'xl',
    'xs',
    false,
  ]),
  title: string,
};

export default Page;
