import { useEffect, useState } from 'react';

import { pickValue, setPickedKeyValue } from 'utils/objects';

function useForm(props = {}) {
  const { initialValues = {}, validationSchema } = props;
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [values, setValues] = useState(
    Object.keys(initialValues).length > 0
      ? initialValues
      : (validationSchema ? validationSchema.getDefault() : {}),
  );

  const getFields = function (schema) {

    return Object
      .fromEntries(schema._nodes
        .map((key) => [key, schema.fields[key]._nodes
          ? getFields(schema.fields[key])
          : true]));
  };

  const getFieldError = function (name) {
    return pickValue(touched, name) && pickValue(errors, name);
  };

  const handleBlur = function ({ target }) {
    if (target?.name) {
      const objTouched = structuredClone(touched);

      setPickedKeyValue(objTouched, target?.name, true);

      setTouched(objTouched);
    }
  };

  const handleChange = function ({ target }) {
    if (target?.name) {
      const objTouched = structuredClone(touched);
      const objValues = structuredClone(values);

      setPickedKeyValue(objTouched, target?.name, true);
      setPickedKeyValue(objValues, target?.name, target?.value);

      setTouched(objTouched);
      setValues(objValues);
    }
  };

  const handleReset = function () {
    setErrors({});
    setTouched({});
    setValues(initialValues);
  };

  const handleSubmit = async function (callback) {
    if (validationSchema) {
      try {
        const parsedValues = validationSchema.cast(values, {
          stripUnknown: true,
        });

        return callback(parsedValues);
      } catch {
        setTouched(getFields(validationSchema));
      }
    } else {
      return callback(values);
    }
  };

  useEffect(() => {
    const objErrors = {};

    try {
      if (validationSchema) {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
      }
    } catch (error) {
      (error?.inner ?? []).forEach((item) => {
        setPickedKeyValue(objErrors, item?.path, item.message);
      });
    } finally {
      setErrors(objErrors);
    }
  }, [values]);

  return {
    form: {
      errors,
      touched,
      values,
    },
    getFieldError,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    setValues,
  };
};

export default useForm;
