import { makeStyles } from '@material-ui/core';
import { number } from 'prop-types';

import {
  loaderLogoImg,
  loaderLogoWImg,
  loaderLogoCircleImg,
  loaderLogoCircleWImg,
} from 'assets/images';

const onIframe = false;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  background: {
    backgroundImage: `url(${onIframe ? loaderLogoWImg : loaderLogoImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '60%',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  image: {
    animation: '$spin 5s linear infinite',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

function LoaderComponent({ width }) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        width: `${width}px`,
        height: `${width}px`,
      }}
    >
      <div className={classes.background} />

      <img
        alt="Loader"
        className={classes.image}
        src={onIframe ? loaderLogoCircleWImg : loaderLogoCircleImg}
        width={`${width}px`}
      />
    </div>
  );
}

LoaderComponent.defaultProps = {
  width: 180,
};

LoaderComponent.propTypes = {
  width: number,
};

export default LoaderComponent;
