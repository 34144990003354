import { useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';

function DateControl(props) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(props.value ? new Date(props.value) : new Date());
  const {
    disabled,
    events = {},
    error,
    helperText,
    label,
    name,
    settings: {
      minPeriod,
      onlyDate,
    } = {},
    size,
    onChange,
    variant,
  } = props;

  const format = {
    year: 'YYYY',
    month: 'YYYY MMMM',
  }[minPeriod] || 'yyyy-MM-DD';

  const openTo = {
    year: 'year',
    month: 'month',
  }[minPeriod] || 'date';

  const views = {
    year: ['year'],
    month: ['year', 'month'],
  }[minPeriod];

  const handleChange = function (date) {
    try {
      let value = new Date(date);

      if (minPeriod === 'year') {
        value = new Date(value.getFullYear(), 0, 1);
      } else if (minPeriod === 'month') {
        value = new Date(value.getFullYear(), value.getMonth(), 1);
      } else {
        value = new Date(value.getFullYear(), value.getMonth(), value.getDate());
      }

      if (onlyDate) {
        value = value.toISOString().split('T').shift();
      }

      const target = {
        name,
        value: value,
      };

      onChange({
        target,
      });

      if (events.onChange) {
        events.onChange(target);
      }

      setSelected(value);
    } catch (err) {
      setSelected(date);
    }
  };

  return (
    <KeyboardDatePicker
      autoOk={true}
      disabled={disabled}
      error={error}
      format={format}
      fullWidth={true}
      helperText={helperText}
      inputVariant={variant}
      InputLabelProps={{
        shrink: `${selected}` !== 'Invalid Date',
      }}
      label={label}
      name={name}
      open={open}
      openTo={openTo}
      size={size}
      value={selected}
      variant="inline"
      views={views}
      onChange={handleChange}
      onClick={() => {
        if (!disabled) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  );
}

DateControl.defaultProps = {
  disabled: false,
  error: false,
  onChange: () => { },
  onBlur: () => { },
};

DateControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  settings: PropTypes.shape({
    minPeriod: PropTypes.oneOf(['year', 'month', 'day']),
    onlyDate: PropTypes.bool,
  }),
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf([
    'outlined',
    'standard',
  ]),
};

export default DateControl;
