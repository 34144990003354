export const SERVICES = {
  ACCOUNTS_SIMULATOR: '/private/accounts/v2/table_virtual',

  APPS_CREDITS_PAYMENTS: '/marketplace/apps/credits/payments',

  APPS_TRUORA_WEB_PROCCESS: '/marketplace/apps/truora/web_proccess',
  APPS_TRUORA_WEB_TOKEN: '/marketplace/apps/truora/web_token',

  APPS_WEETRUST_VERIFY_FLOW: '/marketplace/apps/weetrust/identity/verify_flow',
  APPS_WEETRUST_GET_RESULTS: '/marketplace/apps/weetrust/biometric/getClientResults/:client',

  CATALOGS_ZIPCODE: '/private/codecatalog/code',

  COMPANY: '/private/company',

  CONNECTOR_CORE: '/internal/connector/:company/core',
  CONNECTOR_FLOW: '/internal/connector/:company/flows/:flow',

  PEOPLE: '/private/clients',
  PEOPLE_ORGANIZATIONAL: '/private/clients/organizational/v2',
  PEOPLE_ORGANIZATIONAL_OLD: 'private/clients/organizational',
  PEOPLE_RELATIONSHIP: '/private/clients/relationship',
  PEOPLE_TYPES: '/private/clients/types',

  PRODUCTS: '/private/products',

  USERS_GET_INFO: '/private/users/get_info',
  USERS_UPDATE_ATTRIBUTES: '/users/updateUserAttributes',
  USERS_WEBCLIENTS: '/users/webclients',
  USERS_SIGNIN_DOCUMENT: '/users/signin_documents',
  USERS_PRESIGNIN_DOCUMENT: '/users/presignin_documents',
  USERS_CHECK_DOCUMENT: '/users/checkKeyExist',

  WS_PUBLISH: '/internal/wspublish',
};

export const CONF = {
  [SERVICES.PEOPLE_TEMPLATES]: {
    getById: false,
    sort: 'score',
  },
  [SERVICES.DASHBOARD_CHARTS_DATA]: {
    getById: false,
  },
};
