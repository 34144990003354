import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#EEF6F8',
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 64,
  },
}));
