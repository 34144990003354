import { useState } from 'react';
import { node } from 'prop-types';

import TopBar from 'layouts/Common/TopBar';

import NavBar from './NavBar';
import useStyles from './styles';

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        openMenu={isNavOpen}
        setNavOpen={() => setIsNavOpen(!isNavOpen)}
      />

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        openMenu={isNavOpen}
      />

      <div className={classes[isNavOpen ? 'wrapper' : 'wrapperCollapsed']}>
        <div className={classes.contentContainer}>
          <div
            children={children}
            className={classes.content}
          />
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: node,
};

export default DashboardLayout;
