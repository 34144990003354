import { bool, func } from 'prop-types';
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@material-ui/icons';

import Logo from 'components/Logo';

import useStyles from './styles';

function TopBar(props) {
  const classes = useStyles();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>

        <Grid container>

          <Grid item xs={1}>
            {typeof props.handleBack === 'function' && (<IconButton
              children={<KeyboardArrowLeftIcon />}
              color="primary"
              onClick={props.handleBack}
            />)}
          </Grid>

          {!props.onIframe && (<Grid item xs>
            <Logo
              maxHeight={48}
              type="dark"
            />
          </Grid>)}

          <Grid item xs={1} />

        </Grid>

      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  handleBack: func,
  onIframe: bool,
};

export default TopBar;
