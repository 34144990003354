import { lazy } from 'react';

import { RequestLayout } from 'layouts';

import getRoutes from './routes';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const path = '/request';

const requestRoutes = {
  layout: RequestLayout,
  path,
  routes: getRoutes(path, error404),
};

export default requestRoutes;
