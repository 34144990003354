import { useTranslation as useReactTranslation } from 'react-i18next';

function useTranslation(keyPrefix) {
  const { i18n, t } = useReactTranslation('translation', {
    keyPrefix,
  });

  const translation = function (str) {
    return i18n.exists(`${keyPrefix}.${str}`)
      ? t(str)
      : str;
  };

  return translation;
}

export default useTranslation;
