import { combineReducers } from 'redux';

import authReducer from './auth';
import recoveryReducer from './recovery';
import signupReducer from './signup';

const rootReducer = combineReducers({
  auth: authReducer,
  recovery: recoveryReducer,
  signup: signupReducer,
});

export default rootReducer;
