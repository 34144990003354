import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  DynamicForm,
  Modal,
} from 'components';
import { changePasswordSchema } from 'validations/schema/forms/authentication';
import { parseIfValid } from 'utils/json';

export const TAB = {
  CHANGE_PASSWORD: 'changePassword',
  CURRENT_SESSION: 'currentSession',
  PERMISSIONS: 'permissions',
  PREFERRED_MFA: 'preferredMFA',
};

export const TABS = [
  {
    label: 'Cambiar contraseña',
    value: TAB.CHANGE_PASSWORD,
  },
  {
    label: 'Configurar MFA',
    value: TAB.PREFERRED_MFA,
  },
  {
    hidden: !(process.env.NODE_ENV === 'development'),
    label: 'Permisos',
    value: TAB.PERMISSIONS,
  },
  {
    hidden: !(process.env.NODE_ENV === 'development'),
    label: 'Sesión actual',
    value: TAB.CURRENT_SESSION,
  },
];

function AccountModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(props.recommendMFA
    ? TAB.PREFERRED_MFA
    : [...TABS].filter((tab) => !tab.hidden).shift().value);
  const [formValues, setFormValues] = useState({});
  const [hasSentCode, setHasSentCode] = useState(false);
  const { t } = useTranslation();

  const actions = [
    {
      color: 'error',
      fn: () => setHasSentCode(false),
      hidden: !(currentTab === TAB.PREFERRED_MFA && hasSentCode),
      label: 'Cancelar',
      left: true,
    },
    {
      color: 'primary',
      form: 'frmSetPreferredMFA',
      hidden: !(currentTab === TAB.PREFERRED_MFA),
      label: hasSentCode
        ? 'Validar y activar'
        : {
          SMS: 'Enviar código',
          TOTP: 'Iniciar verificación',
        }[formValues?.mfa] || 'Guardar',
      type: 'submit',
    },
    {
      color: 'primary',
      form: 'frmUpdateUserPassword',
      hidden: !(currentTab === TAB.CHANGE_PASSWORD),
      label: 'Guardar cambio',
      type: 'submit',
    },
  ];

  const handleChangePreferredMFA = async function (values) {
    const { code, mfa, phone_number } = values;
    let message, variant = 'success';

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, 'NOMFA');

      switch (mfa) {
        default:
        case 'NOMFA': {
          if (props.events?.onClose) {
            props.events.onClose();
          }

          message = 'MFA esta desactivado';

          break;
        }

        case 'SMS': {
          if (hasSentCode) {
            await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
            await Auth.setPreferredMFA(user, mfa);

            setHasSentCode(false);

            if (props.events?.onClose) {
              props.events.onClose();
            }

            message = 'MFA mediante mensaje de texto seleccionado';
          } else {
            await Auth.updateUserAttributes(user, {
              phone_number,
            });
            await Auth.verifyCurrentUserAttribute('phone_number');

            setHasSentCode(true);

            message = 'Código de verificación enviado';
          }

          break;
        }

        case 'TOTP': {
          if (hasSentCode) {
            await Auth.verifyTotpToken(user, code);
            await Auth.setPreferredMFA(user, mfa);

            setHasSentCode(false);

            if (props.events?.onClose) {
              props.events.onClose();
            }

            message = 'MFA mediante aplicación seleccionado';
          }
          else {
            const totp = await Auth.setupTOTP(user);
            setFormValues({
              ...formValues,
              username: user.username,
              totp,
            });
            setHasSentCode(true);

            message = 'Iniciado proceso de verificación';
          }

          break;
        }
      }
    } catch (error) {
      message = `auth.${error.code || 'CanNotChangePreferredMfa'}`;
      variant = 'error';
    } finally {
      enqueueSnackbar(t(message), {
        variant,
      });
    }
  };

  const handleChangePassword = async function ({ newPassword, oldPassword }) {
    let message, variant = 'success';

    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(user, oldPassword, newPassword);

      if (props.events?.onClose) {
        props.events.onClose();
      }
    } catch (error) {
      message = `auth.${error.code || 'CanNotChangePassword'}`;
      variant = 'error';
    } finally {
      enqueueSnackbar(t(message), {
        variant,
      });
    }
  };

  useEffect(() => {
    if (props.recommendMFA) {
      setCurrentTab(TAB.PREFERRED_MFA);
    }

    setFormValues(props?.data?.frmSetPreferredMFA || {});
  }, [props]);

  return (<Modal actions={actions} maxWidth="sm" {...props}>
    <>
      <Tabs
        onChange={(_, value) => setCurrentTab(value)}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {TABS.filter((tab) => !tab.hidden).map((item, index) => (
          <Tab key={index} {...item} />
        ))}
      </Tabs>

      <Box mt={2}>

        {currentTab === TAB.CHANGE_PASSWORD && (<DynamicForm
          fields={[
            {
              label: 'Contraseña actual',
              name: 'oldPassword',
            },
            {
              label: 'Contraseña nueva',
              name: 'newPassword',
            },
          ].map((field) => ({
            label: field.label,
            name: field.name,
            type: 'password',
            display: {
              breakpoints: {
                md: 12,
                sm: 6,
                xs: 12,
              },
            },
            settings: {
              canReveal: true,
            },
          }))}
          id="frmUpdateUserPassword"
          handleSubmitData={handleChangePassword}
          validationSchema={changePasswordSchema}
        />)}

        {currentTab === TAB.CURRENT_SESSION && (<DynamicForm
          fields={[
            {
              display: {
                breakpoints: {
                  sm: 6,
                  xs: 12,
                },
              },
              label: 'username',
              name: 'payload.username',
            },
            {
              display: {
                breakpoints: {
                  sm: 6,
                  xs: 12,
                },
              },
              label: 'sub',
              name: 'payload.sub',
            },
            {
              display: {
                breakpoints: {
                  sm: 12,
                  xs: 12,
                },
              },
              label: 'jwtToken',
              name: 'jwtToken',
              type: 'textarea',
              settings: {
                multiline: true,
                rows: 3,
                rowsMax: 5,
              },
            },
          ]}
          initialValues={{
            ...props?.data?.frmUserToken?.signInUserSession?.accessToken,
          }}
        />
        )}

        {currentTab === TAB.PERMISSIONS && (<>
          <pre
            children={JSON.stringify(
              parseIfValid(localStorage.getItem('permissions'), false),
              null,
              ' ',
            )}
          />
        </>)}

        {currentTab === TAB.PREFERRED_MFA && (<>
          {props.recommendMFA && (<Box mb={2}>
            <Alert severity="warning">
              <AlertTitle>Alerta de seguridad</AlertTitle>
              Se recomienda que actives la autenticación multifactor (MFA)
            </Alert>
          </Box>)}

          <DynamicForm
            fields={[
              {
                disabled: hasSentCode,
                display: {
                  breakpoints: {
                    md: 12,
                    sm: 6,
                    xs: 12,
                  },
                },
                events: {
                  onChange: function (target) {
                    setFormValues({
                      ...formValues,
                      [target.name]: target.value,
                    });
                  },
                },
                label: 'Tipo',
                name: 'mfa',
                settings: {
                  options: [
                    {
                      id: 'NOMFA',
                      name: 'Sin MFA',
                    },
                    {
                      id: 'TOTP',
                      name: 'Aplicación de autenticación',
                    },
                    {
                      id: 'SMS',
                      name: 'Mensaje de texto',
                    },
                  ],
                },
                type: 'select',
              },
              {
                disabled: hasSentCode,
                label: 'Número de teléfono',
                name: 'phone_number',
                settings: {
                  format: 'phone_number',
                },
                type: formValues.mfa === 'SMS'
                  ? 'number'
                  : 'hidden',
              },
              {
                label: 'Escanea el código con la aplicación de autenticación',
                type: (formValues.mfa === 'TOTP' && hasSentCode)
                  ? 'divider'
                  : 'hidden',
              },
              {
                label: (<Box m={1} textAlign="center">
                  <QRCode
                    size={200}
                    value={[
                      'otpauth://totp/AWSCognito:',
                      formValues?.username,
                      '?secret=',
                      formValues?.totp,
                      '&issuer=DynamiCore',
                    ].join('')}
                  />
                </Box>),
                type: (formValues.mfa === 'TOTP' && hasSentCode)
                  ? 'description'
                  : 'hidden',
              },
              {
                label: `Ingresa el código ${{
                  SMS: 'recibido por mensaje de texto',
                  TOTP: 'generado en la aplicación de autenticación',
                }[formValues?.mfa]}`,
                type: hasSentCode
                  ? 'divider'
                  : 'hidden',
              },
              {
                name: 'code',
                type: hasSentCode
                  ? 'splitted'
                  : 'hidden',
              },
            ]}
            id="frmSetPreferredMFA"
            initialValues={props?.data?.frmSetPreferredMFA || {}}
            handleSubmitData={handleChangePreferredMFA}
          />

        </>)}

      </Box>

    </>
  </Modal >);
}

AccountModal.defaultProps = {
  events: {},
  open: false,
  recommendMFA: false,
  title: 'Configurar cuenta',
};

AccountModal.propTypes = {
  data: PropTypes.shape({
    frmSetPreferredMFA: PropTypes.object,
    frmUserToken: PropTypes.object,
  }),
  events: PropTypes.shape({
    onClose: PropTypes.func,
  }),
  open: PropTypes.bool,
  recommendMFA: PropTypes.bool,
  title: PropTypes.string,
};

export default AccountModal;
