import {
  useRef,
  useState,
} from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import { availableLanguages, getCurrentLanguaje } from 'locales';
import { getCurrentSessionData, updateUserAttributes } from 'utils/aws/cognito';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 200,
  },
  listItem: {
    cursor: 'pointer',
  },

}));

function Language() {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { i18n, t } = useTranslation('translation', {
    keyPrefix: 'system.menu.language',
  });
  const currentLanguaje = getCurrentLanguaje();
  const flags = {
    es: 'MX',
    en: 'US',
  };

  const handleOpen = function () {
    setOpen(true);
  };

  const handleClose = function () {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('tooltip')}>
        <Button
          children={String(currentLanguaje).toUpperCase()}
          className={classes.icon}
          color="inherit"
          onClick={handleOpen}
          ref={ref}
          startIcon={<ReactCountryFlag
            countryCode={flags[currentLanguaje]}
            svg
          />}
          variant="text"
        />
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popover,
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <List className={classes.list} disablePadding>
          {availableLanguages.map((item, index) => (<ListItem
            className={classes.listItem}
            key={index}
            onClick={function () {
              handleClose();

              i18n.changeLanguage(item);

              const { userData } = getCurrentSessionData();

              updateUserAttributes(userData.Username, {
                locale: item,
              });
            }}
            selected={currentLanguaje === item}
          >
            <ListItemIcon>
              <ReactCountryFlag
                countryCode={flags[item]}
                style={{
                  fontSize: '1.5em',
                  lineHeight: '1.5em',
                }}
                svg
              />
            </ListItemIcon>
            <ListItemText primary={t(`items.${item}`)} />
          </ListItem>))}
        </List>
      </Popover >
    </>
  );
}

export default Language;
