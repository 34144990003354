import { lazy } from 'react';

export default function getRoutes(parent = '', error404) {

  return [
    {
      exact: true,
      path: '/auth/login',
      component: lazy(() => import('views/Authentication/SignIn')),
    },
    {
      exact: true,
      path: '/auth/recovery',
      component: lazy(() => import('views/Authentication/RecoveryView')),
    },
    error404,
  ];
}
