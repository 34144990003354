import {
  any,
  arrayOf,
  bool,
  func,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

const optionsProps = oneOfType([
  arrayOf(shape({
    id: oneOfType([
      any,
      string,
    ]),
    name: string,
  })),
  func,
]);

export const SELECT_PROP_TYPES = {
  disabled: bool,
  error: bool,
  events: object,
  helperText: string,
  label: string,
  name: string,
  settings: shape({
    autocomplete: bool,
    emptyElement: string,
    group: bool,
    multiple: bool,
    nullItem: oneOfType([
      bool,
      shape({
        id: string,
        name: string,
      }),
    ]),
    options: optionsProps,
  }),
  size: oneOf([
    'small',
    'medium',
  ]),
  value: any,
  onChange: func,
  onBlur: func,
};

export const SELECT_AUTOCOMPLETE_PROP_TYPES = {
  disabled: bool,
  error: bool,
  events: object,
  label: string,
  name: string,
  options: optionsProps,
  helperText: string,
  size: string,
  value: any,
  onBlur: func,
  onChange: func,
};

export const SELECT_MENU_PROP_TYPES = {
  disabled: bool,
  emptyElement: string,
  error: any,
  events: object,
  group: bool,
  helperText: string,
  label: string,
  name: string,
  onBlur: func,
  onChange: func,
  options: optionsProps,
  size: string,
  value: any,
  variant: oneOf([
    'filled',
    'outlined',
    'standard',
  ]),
};

export const SELECT_MULTIPLE_PROP_TYPES = {
  disabled: bool,
  error: any,
  events: object,
  helperText: string,
  label: string,
  name: string,
  onChange: func,
  options: optionsProps,
  size: string,
  value: arrayOf(any),
};
