import { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { Amplify } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  CssBaseline,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/es';

import {
  Auth,
  ScrollReset,
} from 'components';
import Routes from 'routes';
import { createTheme } from 'theme';
import { initSettings } from 'utils/settings';
import './global.css';

const history = createBrowserHistory();
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});
const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);

function App() {
  const [hasSettings, setHasSettings] = useState(false);
  const [theme, setTheme] = useState({});
  useStyles();

  const getAwsAmplify = function (settings = {}) {
    const { user } = settings?.providers?.aws || {};
    const [region] = (user.poolsId || '').split('_');

    return {
      aws_cognito_identity_pool_id: `${region}:${user.identityPoolId || 'e12caba4-f0c8-4b5c-805b-4e596c61ed8f'}`,
      aws_cognito_region: region,
      aws_project_region: region,
      aws_user_pools_id: user.poolsId,
      aws_user_pools_web_client_id: user.webClientId,
      oauth: {},
    };
  };

  useEffect(() => {
    initSettings()
      .then(function (settings) {
        setTheme(settings?.theme);
        setHasSettings(!!settings);

        Amplify.configure(getAwsAmplify(settings));
      });
  }, []);

  if (!hasSettings) {
    return null;
  }

  return (<>

    <CssBaseline />

    <ThemeProvider theme={createTheme(theme)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
          <SnackbarProvider maxSnack={3}>
            <Router history={history}>
              <Auth
                children={[
                  <ScrollReset key="scrollReset" />,
                  <Routes key="routes" />,
                ]}
              />
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>

  </>);
}

export default App;
