import { lazy } from 'react';

import { STEP } from 'views/Request/Common/constants';

export default function getRoutes(parent = '', error404) {

  return [
    {
      exact: true,
      path: `${parent}`,
      component: lazy(() => import('views/Request/Home')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.CONFIRM}`,
      component: lazy(() => import('views/Request/Confirm')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.CUSTOMER}`,
      component: lazy(() => import('views/Request/Customer')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.DOCUMENTATION}`,
      component: lazy(() => import('views/Request/Documentation')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.FINISH}`,
      component: lazy(() => import('views/Request/Finish')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.IDENTITY}`,
      component: lazy(() => import('views/Request/Identity')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.IDENTITY}/dicio`,
      component: lazy(() => import('views/Request/Identity/Dicio')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.IDENTITY}/weetrust`,
      component: lazy(() => import('views/Request/Identity/WeeTrust')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.IDENTITY}/truora`,
      component: lazy(() => import('views/Request/Identity/Truora')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.LOAN}`,
      component: lazy(() => import('views/Request/Loan')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.REGISTER}`,
      component: lazy(() => import('views/Request/Register')),
    },
    {
      exact: true,
      path: `${parent}/${STEP.SIMULATOR_RESULTS}`,
      component: lazy(() => import('views/Request/SimulatorResults')),
    },
    error404,
  ];
}
