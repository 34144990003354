import { Fragment } from 'react';
import {
  arrayOf,
  bool,
  element,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import {
  Box,
  Slider,
  Tooltip,
  Typography,
} from '@material-ui/core';

function Fieldset({ label, children }) {
  return (
    <fieldset style={{
      borderRadius: 5,
    }}>
      <legend style={{
        marginLeft: 8,
      }}>
        <Typography color="textSecondary" variant="caption">
          <Box style={{
            marginLeft: 5,
            marginRight: 5,
          }}>
            {label}
          </Box>
        </Typography>
      </legend>
      <Box style={{
        marginLeft: 15,
        marginRight: 15,
      }}>
        {children}
      </Box>
    </fieldset>);
}

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

function SliderControl(props) {
  const {
    events = {},
    label,
    name,
    value,
    settings = {},
    onChange,
  } = props;
  const {
    max = 100,
    min = 0,
    step = max / 10,
  } = settings;

  const Container = (label && settings.variant !== 'independent')
    ? Fieldset
    : Fragment;

  const hasOptions = Array.isArray(settings.options);

  const handleSliderChange = (event, value) => {
    const target = {
      name,
      value,
    };

    onChange({
      target,
    });

    if (events.onChange) {
      events.onChange(target);
    }
  };

  return (
    <Container label={label}>
      {(label && settings.variant === 'independent') && (
        <Typography
          align="center"
          children={label}
          style={{
            fontSize: '0.8rem',
            fontWeight: 'bold',
          }}
        />
      )}
      <Slider
        style={{
          marginTop: '-15px',
        }}
        defaultValue={value}
        max={hasOptions
          ? [...settings.options].pop()?.id
          : max}
        marks={hasOptions
          ? settings.options.map((option) => ({
            label: option.id,
            value: option.id,
          }))
          : undefined}
        min={hasOptions
          ? [...settings.options].shift()?.id
          : min}
        onChange={handleSliderChange}
        step={hasOptions ? null : step}
        valueLabelDisplay={(settings?.labelDisplay ?? true) ? 'auto' : 'off'}
        valueLabelFormat={function (val) {
          if (hasOptions) {
            const selectOption = settings
              .options
              .find((option) => option.id === val);

            return selectOption?.name ?? val;
          } else {
            return val;
          }
        }}
        ValueLabelComponent={hasOptions
          ? ValueLabelComponent
          : undefined}
      />
    </Container>
  );
}

Fieldset.propTypes = {
  label: string,
  children: element,
};

ValueLabelComponent.propTypes = {
  children: node,
  open: bool,
  value: string,
};

SliderControl.defaultProps = {
  value: 0,
  onChange: () => { },
  onBlur: () => { },
};

SliderControl.propTypes = {
  events: object,
  label: string,
  name: string,
  onChange: func,
  settings: shape({
    labelDisplay: bool,
    max: number,
    min: number,
    options: arrayOf(shape({
      id: oneOfType([number, string]),
      name: string,
    })),
    step: number,
    variant: oneOf([
      'default',
      'independent',
    ]),
  }),
  value: number,
};

export default SliderControl;
