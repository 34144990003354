import {
  Fragment,
  Suspense,
  lazy,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen';

import authenticationRoutes from './authentication';
import dashboardRoutes from './dashboard';
import publicRoutes from './public';
import requestRoutes from './request';

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, index) => {
          const Component = route.component;
          const Guard = route.guard ?? Fragment;
          const Layout = route.layout ?? Fragment;

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes
                      ? (renderRoutes(route.routes))
                      : (<Component {...props} />)}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense >
  ) : null;

function Routes() {
  return renderRoutes([
    {
      exact: true,
      path: '/',
      component: () => <Redirect to="/request" />,
    },
    authenticationRoutes,
    dashboardRoutes,
    publicRoutes,
    requestRoutes,
    {
      component: lazy(() => import('views/Errors/Error404')),
    },
  ]);
}

export default Routes;
