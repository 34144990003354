import { Link as RouterLink } from 'react-router-dom';
import {
  bool,
  func,
  string,
} from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from '@material-ui/icons';

import Logo from 'components/Logo';
import { THEMES } from 'constants/index';

import Account from './Account';
import Language from './Language';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {},
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar(props) {
  const classes = useStyles();
  const {
    className,
    onMobileNavOpen,
    openMenu,
    setNavOpen,
    isMenuCollapsible,
    ...rest
  } = props;

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>

        {isMenuCollapsible && (<>
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
            >
              <SvgIcon
                children={<MenuIcon />}
                fontSize="small"
              />
            </IconButton>
          </Hidden>

          <Hidden mdDown>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={setNavOpen}
            >
              <SvgIcon
                children={openMenu ? <MenuOpenIcon /> : <MenuIcon />}
                fontSize="small"
              />
            </IconButton>

          </Hidden>

        </>)}

        <RouterLink to="/">
          <Logo
            maxHeight={48}
            variant="imagotipo-horizontal"
          />
        </RouterLink>

        <Box ml={2} flexGrow={1} />

        {false && <Language />}

        <Box ml={2}>
          <Account />
        </Box>

      </Toolbar>
    </AppBar>
  );
}

TopBar.defaultProps = {
  isMenuCollapsible: true,
};

TopBar.propTypes = {
  className: string,
  isMenuCollapsible: bool,
  onMobileNavOpen: func,
  openMenu: bool,
  setNavOpen: func,
};

export default TopBar;
