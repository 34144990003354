import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import { DashboardLayout } from 'layouts';

import getRoutes from './routes';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const path = '/dashboard';

const dashboardRoutes = {
  guard: AuthGuard,
  layout: DashboardLayout,
  path,
  routes: getRoutes(path, error404),
};

export default dashboardRoutes;
