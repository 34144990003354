import { lazy } from 'react';

export default function getRoutes(parent = '', error404) {

  return [
    {
      exact: true,
      path: `${parent}/identity/dicio`,
      component: lazy(() => import('views/Public/Identity/Dicio')),
    },
    error404,
  ];
}
